<template>
  <b-container>
  </b-container>
</template>

<script>

export default {
  name: 'Homepage'
};
</script>
